/* eslint-disable import/named */
import React from 'react';
import { ContactForm } from '../components';

const Contact = () => {
  return (
    <div style={{ width: '100%' }}>
      <ContactForm />
    </div>
  );
};
export default Contact;
