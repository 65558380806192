/* eslint-disable import/named */
import React from 'react';
import { ProjectList } from '../components';

const Projects = () => {
  return (
    <div style={{ width: '100%' }}>
      <ProjectList></ProjectList>
    </div>
  );
};
export default Projects;
